:root {
  --padding-xsmall: 6px;
  --padding-small: 16px;
  --padding-medium: 24px;
  --padding-mediumToLarge: 36px;
  --padding-large: 48px;
  --padding-xlarge: 96px;
}

.App {
}

.content-wrapper {
  /* padding-left: var(--padding-large);
  padding-right: var(--padding-large); */
}

.App-header {
  display: flex;
  justify-content: center;
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
}

/* App Bar */
.app-bar {
  text-align: center;
  margin-top: 8px;
}

.nav-item {
  margin-left: 16px;
}

/* Content */

ul {
  list-style-type: none;
  padding-left: var(--padding-small);
}

li {
  padding-bottom: var(--padding-small);
}

.work-page-wrapper {
  padding-top: var(--padding-medium);
}

.content-wrapper {
  max-width: 630px;
  margin: auto;
  padding-left: var(--padding-small);
  padding-right: var(--padding-small);
  padding-bottom: var(--padding-xlarge);
}

.home-image-pane {
  text-align: center;
}

h3 {
  margin-bottom: 0;
}

.photo {
  width: 230px;
}

.home-flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: wrap;
  justify-content: center;
}

.education-flexbox {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  align-items: center;
}

.education-flexbox div {
  padding-right: var(--padding-medium);
  padding-bottom: var(--padding-small);
}

.centered {
  text-align: center;
}

.case-study-image {
  width: 100%;
  padding-top: var(--padding-small);
  padding-bottom: var(--padding-medium);
}

.case-study-card {
  background-color: white;
  padding: var(--padding-small);
  border-radius: 6px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: var(--padding-small);
}

.detail-text {
  color: black; /* Ensure the detail text stays black */
  font-weight: normal; /* Adjust the weight if needed */
}